/* LocaisPage.css */
.locais-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.local {
    border: 1px solid #ccc;
    padding: 10px;
    flex: 1;
    min-width: 300px; /* Ajuste a largura mínima conforme necessário */
}

.local h2 {
    margin: 0 0 10px;
}
