/* Container para as empresas */
.empresas-container {
    display: flex;
    flex-wrap: wrap; /* Isso permite que os itens passem para a próxima linha conforme necessário */
    gap: 10px; /* Espaçamento entre os quadros */
    padding: 10px; /* Espaçamento em volta do container */
}

/* Estilo para cada empresa */
.empresa {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 0; /* Remova a margem, pois agora o espaçamento é controlado pelo gap */
    flex: 1; /* Isso permite que os quadros cresçam para preencher o espaço disponível */
    min-width: 250px; /* Largura mínima para cada quadro */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Sombra opcional para um pouco de profundidade */
}

.empresa h2 {
    margin: 0 0 10px;
}
