/* HorariosPage.css */
.horarios-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
}

.horarios-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.horarios-header h2 {
    margin: 0;
}

.horarios-select {
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 10px;
}

.horario-card {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 10px;
}

.horario-info {
    margin: 5px 0;
}

.horario-info strong {
    font-weight: bold;
}

.horario-info span {
    margin-left: 5px;
}

.horario-action {
    text-align: right;
    margin-top: 10px;
}

.horario-action button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-size: 14px;
}

.horario-action button:hover {
    background-color: #0056b3;
}
