.header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: white;
    padding: 1rem;
}

.header-bar h1 {
    margin: 0;
}

nav a, .dropbtn {
    color: white;
    text-decoration: none;
    padding: 0.5rem;
    display: inline-block;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #555;}

/* Estilização Geral do Botão */
.dropbtn {
    background-color: #333; /* Cor de fundo do botão */
    color: white; /* Cor do texto */
    padding: 10px 16px; /* Espaçamento interno */
    border: none; /* Remove a borda */
    cursor: pointer; /* Muda o cursor para indicar que é clicável */
    border-radius: 5px; /* Borda arredondada */
    transition: background-color 0.3s; /* Transição suave para o hover */
}

/* Efeito ao passar o mouse por cima */
.dropbtn:hover, .dropbtn:focus {
    background-color: #555; /* Cor de fundo ao passar o mouse ou focar */
}

/* Ajusta o background do dropdown para combinar com o botão */
.dropdown-content {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Ajustes para os links dentro do dropdown */
.dropdown-content a {
    padding: 12px 16px; /* Espaçamento interno */
    text-decoration: none; /* Remove o sublinhado */
    display: block; /* Faz cada link ocupar sua própria linha */
}

.dropdown-content a:hover {
    background-color: #ddd; /* Cor de fundo ao passar o mouse por cima do link */
}
.link-button {
    background-color: #333; /* Cor de fundo do botão */
    color: white; /* Cor do texto */
    padding: 10px 16px; /* Espaçamento interno */
    border: none; /* Remove a borda */
    cursor: pointer; /* Muda o cursor para indicar que é clicável */
    border-radius: 5px; /* Borda arredondada */
    text-decoration: none; /* Remove o sublinhado do link */
    transition: background-color 0.3s; /* Transição suave para o hover */
    margin: 0 5px; /* Espaçamento externo para separar dos elementos adjacentes */
}

.link-button:hover {
    background-color: #555; /* Cor de fundo ao passar o mouse ou focar */
}
