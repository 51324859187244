.escala-container {
    padding: 20px;
}

.filters {
    display: flex;
    align-items: center;
    gap: 20px; /* Espaçamento entre o select e o input de data */
    margin-bottom: 20px; /* Espaço antes dos detalhes do horário */
}

.horario-details {
    margin-top: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}
