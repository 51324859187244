.plantonistas-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
}
.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    font-size: 16px;
}

.icon-button:hover {
    color: #007bff;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table, th, td {
    border: 1px solid #ddd;
}

th, td {
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}
